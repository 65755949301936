.mobileNav {    
    background: $red;
    height: 100vh;
    position: fixed;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    top: 0;
    left: -45vw;
    width: 45vw;
    transition: all $transitionFast ease-in-out;
    z-index: 1000;
    .hiddenNav {
        a {
            border-top: solid 1px rgba(9,9,9, 0.5);
        }
    }
    &.is-active {
        left: 0;
        width: 45vw;
        ul {
            width: 100%;
            li {
                font-family: $bebas;
                font-size: 20px;
                border-top: solid 1px rgba(9,9,9, 0.5);
                display: block;
                width: 100%;
                padding: 0 0 0 20px;
                a {
                    padding: 8px 5px;
                }
                &:last-child, &:first-child {
                    display: none;
                }
                &.logo {
                    display: none;
                }
            }
            .dropdown-menu {
                background: none;
                border: none;
                position: static !important;
                margin: 0 0 0 -20px;
                padding: 5px 0;
                transform: none !important;
                a {
                    padding: 5px 20px;
                }
            }
        }
    }
    .dropdown-toggle.subActive {
        &:after {
            content: '\f078';
            font-family: 'FontAwesome';
            float: right;
        }
    }
    .dropdown-toggle {
        &:after {
            border: none;
            content: '\f054';
            font-family: 'FontAwesome';
            float: right;
            position: relative;
            transition: all $transitionFast ease-in-out;
            z-index: 2;
        }
    }

    @include screen {
        display: none;
    }
    
    @include touch {
        .nav-wrapper {
            position: relative;
        }
        // Subnav default styles
        .subnav {
            position: absolute !important;
            top: 0;
            left: 0;
            right: 0;
            
            li {
                visibility: hidden;
                width: 0;
            }
        }
    }
    
    img {
        display: block;
        max-width: 200px;
        padding: 40px;
        margin: 0 auto;
    }
    nav {
        ul {
            margin: 0 auto;
            width: 85%;
            
            li {
                border-top: 1px solid $grey6;
                font-weight: 500;
                letter-spacing: .03em;
                
                &.back {
                    font-size: 16px!important;
                    text-transform: uppercase;
                    
                    a {
                        color: $grey9;
                    }
                    i {
                        color: $grey9;
                        float: left;
                        margin-right: 8px;
                    }
                }
                
                &:last-child {
                    border-bottom: 1px solid $grey6;
                }
                &.divider {
                    display: none;
                }
                div {
                    padding: 0;
                    display: none;
                }
                a {
                    color: #FFF;
                    display: block;
                    text-transform: uppercase;
                }
                i {
                    float: right;
                    color: $red;
                    padding: 4px 0 4px 0;
                }
            }
            &.subnav {
                margin-left: 40px;
                z-index: -1;
                width: 85%;
                margin: 0 auto!important;
                
                &.subActive {
                    z-index: 10;
                    margin-left: 40px;
                }
            }
        }
    }
}


@keyframes animateInFromLeft {
    0% {
        width: 0;
        visibility: hidden;
        transform: translate3d(-100vw, 0, 0);
    }
    1% {
        width: 100%;
    }
    100% {
        visibility: visible;
        width: 100%;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes animateInFromRight {
    0% {
        visibility: hidden;
        width: 0;
        transform: translate3d(100vw, 0, 0);
    }
    1% {
        width: 100%;
    }
    100% {
        visibility: visible;
        width: 100%;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes animateOutToRight {
    0% {
        visibility: visible;
        width: 100%;
        transform: translate3d(0, 0, 0);
    }
    99% {
        width: 100%;
    }
    100% {
        visibility: hidden;
        width: 0;
        transform: translate3d(100vw, 0, 0);
    }
}

@keyframes animateOutToLeft {
    0% {
        visibility: visible;
        width: 100%;
        transform: translate3d(0, 0, 0);
    }
    99% {
        width: 100%;
    }
    100% {
        visibility: hidden;
        width: 0;
        transform: translate3d(-100vw, 0, 0);
    }
}

.animate-inFromLeft {
    animation: animateInFromLeft 1s cubic-bezier(0.7, 0, 0.3, 1);
    animation-fill-mode: both;
}

.animate-inFromRight {
    animation: animateInFromRight 1s cubic-bezier(0.7, 0, 0.3, 1);
    animation-fill-mode: both;
}

.animate-outToLeft {
    animation: animateOutToLeft 1s cubic-bezier(0.7, 0, 0.3, 1);
    animation-fill-mode: both;
}

.animate-outToRight {
    animation: animateOutToRight 1s cubic-bezier(0.7, 0, 0.3, 1);
    animation-fill-mode: both;
}