.accordian {
    .infoBlock {
        border-bottom: 4px solid $white;
            .header {
                background-color: $greyE;
                @extend .smooth;
               
                h3 {
                    font-size: 25px;
                    font-weight: 600;
                    width: 85%;
                    float: left;
                }
                i {
                    color: $grey5;
                    font-size: 20px;
                    float: right;
                    position: relative;
                    top: 4px;
                }
                &:hover {
                    background-color: $greyEAlt;
                    
                    i {
                        color: $red;
                    }
                }

                .accordion-button {
                    padding: 15px;
                    cursor: pointer;

                    .fa-plus {
                        backface-visibility: hidden;
                        transform: rotate(0deg);
                        transform-style: preserve-3d;
                        transition: transform .6s;
                    }

                    &.expanded {
                        .fa-plus {
                            transform: rotate(45deg);
                        }
                    }
                }
            }
            .accordion-content {
                background-color: $greyEAlt;
                overflow: hidden;
                transition: height .6s;

                p {
                    font-size: 18px;
                    margin: 20px;

                    &:empty {
                        margin: 0;
                    }
                }
            }
    }
}