#error {
    @extend .backgroundSettings;
    width: 100vw;
    height: 100vh;
    background: $white;
    
    .content {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        margin: 0;
        z-index: 999;
        top: 40%;
        
        h1 {
            font-family: $primary!important;
            font-size: 80px!important;
            color: $grey5!important;
            text-transform: uppercase!important;
            margin-bottom: 15px!important;
            
            @include mobile {
                font-size: 72px!important;
            }
            @include small-mobile {
                font-size: 60px!important;
            }
        }
    }
}