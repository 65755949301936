header {
    position: relative;
    z-index: 900;
    transition: all 0.2s ease;
    .icon-color {
        color: #fff;
        size: 16px !important;
        padding: 8px;
        padding-left: 0px;
    }
    

    .hamburger {
        border: none;
        outline: none;
        display: none;
        position: absolute;
        top: 40px;
        right: 20px;
        opacity: 1!important;
        
        @include touch {
            display: block;
        }
        @include small-mobile {
            top: 64px;
        }
        
        &.is-active {
            border: none;
        }
        .hamburger-inner {
            background-color: $red!important;
            
            &:before {
                background-color: $red!important;
            }
            &:after {
                background-color: $red!important;
            }
        }
    }
    &.sticky {
        .utility {
            display: inline-block !important;
            padding: 0;
            position: absolute;
            ul {
                li {
                    padding: 2px 5px;
                    img {
                        max-width: 10px;
                    }
                    &:nth-child(4) {
                        img {
                            max-width: 7px;
                        }
                    }
                }
            }
            @include tablet {
                padding: 10px 0;
            }
        }
        position: fixed;
        background: $red;
        top: 0;
        left: 0;
        right: 0;
        padding: 0px !important;
        .primaryNav {
            background: transparent;
            padding: 0px;
            ul {
                margin-top: 7px;
                li {
                    &.logo {
                        max-width: 200px;
                        a { 
                            border-bottom: $red solid 2px;
                            &:hover, &.active {
                                border-bottom: none;
                            }
                        }
                        img {
                            background-image: none;
                            top: -10px;
                        }
                    }
                    a { 
                        
                        border-bottom: $red solid 2px;
                    }
                    .hiddenNav {
                        a {
                            border-bottom: $red solid 4px;
                            &:hover {
                                border-bottom: $red solid 4px;
                                color: #FFF;
                            }
                            &.active {
                                border-bottom: $red solid 4px;
                                color: #FFF;
                                font-weight: 700;
                            }
                        }
                    }
                    .dropdown-menu {
                        
                        a {
                            color: $black;
                            padding: 0 10px;
                        }
                    }
                    a {
                        color: $white;
                        &.facebookNav {
                            color: $red;
                            background: $white;
                        }
                        &:hover, &.active {
                            color: $black;
                            border-bottom: solid 2px $white;
                        }
                        .icon-color {
                            color: $red;
                            size: 16px !important;
                            padding: 8px;
                            padding-left: 0px;
                        }
                    }
                }
            }
            .button.compact-red-nav {
                font-family: "Crete Round", serif;
                font-weight: 300;
                font-size: 17px;
                font-style: italic;
                background: #B5121B;
                color: #fff;
                text-align: center;
                padding: 5px 55px;
                margin: 8px 37px 8px 0;
                border-color: #fff;
                border-radius: 1px;
                text-transform: none;
                border-style: solid;
                border-width: thin;
                &:hover {
                    background: $black;
                    color: $white;
                    border-color: $black !important;
                }
            }
            .button.compact-white-nav {
                font-family: "Crete Round", serif;
                font-weight: 300;
                font-size: 17px;
                font-style: italic;
                background: #fff !important;
                color: #B5121B !important;
                text-align: center;
                margin: 8px 37px 8px 0;
                margin-bottom: 0px;
                border-color: #B5121B !important;
                border-radius: 1px;
                text-transform: none;
                border-style: solid !important;
                border-width: thin;
                padding: 5px !important;
                margin-top: 0px;
                margin-bottom: 0px;
                min-width: 195px;
                &:hover {
                    background: $black;
                    color: $white;
                    border-color: $black !important;
                }
            }
        }
        .mobile-band-logo {
            li {
                a {
                    border-bottom: none !important;
                    margin-top: 40px;
                }
            }
        }
    }
}
.utility {
    width: 100%;
    background: $red;
    position: relative;
    z-index: 902;
    padding: 10px 20px;
    padding-left: 10%; // temp
    transition: all 0.2s ease;
    ul {
        li {
            padding: 0 10px;
            font-size: .8em;
            a {
                font-family: $secondary;
                color: $white;
                text-transform: uppercase;
                text-decoration: underline !important;
                font-weight: bold;
            }
        }
    }
    @include small-mobile {
        padding: 0px;
        .container-lg{
            padding-left:0px;
        }
    }
    @include xs-mobile {
        padding: 0px;
        .container-lg{
            padding-left:0px;
        }
    }
}
.primaryNav {
    /*padding: 25px 0;*/
    padding: 0px;
    padding-top:6px;
    position: relative;
    background: $white;
    font-family: $secondary;
    font-weight: 600;
    
    @include small-mobile {
        padding: 15px;
    }
    @include xs-mobile {
        padding: 15px;
    }
    .mobile-band-logo {
        display: flex !important;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        padding: 0px !important;
        margin: 0px !important;
        list-style: none;
        background-color: #fff !important;
        border-bottom: none !important; 
        li {
            display: flex !important;
            a {
                &:hover, &:active {
                    border-bottom: none !important;
                }
                img {
                    max-width: 75px;
                    padding-bottom: 5px;
                }
            }
        }

    &:hover, &:active {
        border: none !important;
    }

    display:none !important;
    @media (max-width: 1021px) { 
        display:flex !important;
     }
    }
        
    .logo {
        padding: 0;
        position: relative;
        width: 15%;
        max-width: 200px;
        z-index: 1001;
        margin-top:-10px;
        a {
            border: none;
            display: block;
            margin-top: 0px;
            position: relative;
            &.active {
                border: none !important;
            }
            img {
                /*background-image: url('/assets/img/logoBg.gif');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center 73%;
                position: absolute;*/
                margin: 0 auto;
                padding: 0 16px 0 16px;
                left: 0;
                top:-75%;
                @include mobile {
                    width: 270px;
                }
                @include small-mobile {
                    width: 250px;
                }
                @include xs-mobile {
                    width: 225px;
                }
                a {
                    transition: none;
                    &:hover, &:active {
                        border-bottom: none;
                        text-decoration: none;
                        transition: none;
                    }
                }
            }
            &:hover, &.active {
                border-bottom: none;
                text-decoration: none;
                transition: none;
            }
        }
        a:hover, a:active {
            border-bottom: none;
            text-decoration: none;
        }
    }
    nav {
        
        @include touch {
            display: none;
        }
        ul {
            font-family: $secondary;
            font-weight: 600;
            li {
                font-size: 17px;
                font-weight: 500;
                text-transform: uppercase;
                display: inline-block;
                padding: 25px 15px 15px;
                .dropdown-menu {
                    border: none;
                    &.show {
                        display: block !important;
                        position: relative !important;
                    }
                    a {
                        padding: 0px;
                        &:hover {
                            border-bottom: none;
                            padding: 0px;
                        }
                    }
                    
                }
                .logo {
                    a:link {
                        transition: none;
                    }
                }
                .hiddenNav {
                    display: none;
                    max-width: 20px;
                    font-size: .8em;
                    font-family: $body;
                    text-transform: none;
                    top: 15px;
                    transition: none;
                    transform: none;
                    padding-top: 5px;
                    a {
                        display: block;
                        padding-bottom: 5px;
                        transition: none;
                        transform: none;
                        white-space: nowrap;
                        &:hover {
                            border-bottom: #FFF solid 2px;
                            text-decoration: underline;
                            font-weight: normal;
                            color: $red;
                        }
                        &.active {
                            color: $red;
                            font-weight: 700;
                            border-bottom: #FFF solid 2px;
                        }
                    }
                }
                a {
                    color: $black;
                    padding: 0 0 0px 0;
                    border-bottom: solid 2px $white;
                    &.compact-white-nav {
                        font-family: $hero;
                        font-weight: 300;
                        font-size: 17px;
                        font-style:italic;
                        background: #fff;
                        color: #B5121B !important;
                        text-align: center;
                        margin: 8px 37px 8px 0;
                        border-radius: $radius;
                        text-transform:none;
                        border-color: #B5121B !important;
                        border-radius: 1px;
                        padding: 5px !important;
                        margin-top: 0px;
                        margin-bottom: 0px;
                        min-width: 195px;
                        @include handheld {
                            font-size: 15px;
                            padding: 10px 20px;
                        }
                        
                        &:hover {
                            background: $black;
                            color: $white !important;
                            border: solid 2px $black !important;
                        }
                    } 
                    &.facebookNav {
                        color: $white;
                        background: $red;
                        border: none;
                        border-radius: 25px;
                        font-size: .8em;
                        font-family: $secondary;
                        padding: 5px 15px;
                        img {
                            max-height: 20px;
                            margin-top: -4px;
                            padding: 3px 10px 3px 0;
                        }
                    }
                    &:hover, &.active {
                        border-bottom: solid 2px $red;
                    }
                    &.dropdown-toggle {
                        &::after {
                            display: none;
                        }
                    }
                    .dropdown-menu {
                        display: block;
                        position: relative;
                    }
                }
                &:nth-child(4) {
                    padding-right:0;
                }
                &:nth-child(6) {
                    padding-left:0;
                }
                &:last-child {
                    @include small-desktop {
                        max-width: 220px;
                        padding: 25px 12px 15px;
                        form {
                            input {
                                max-width: 120px;
                            }
                        }
                        .button.compact-red-nav {
                            padding: 5px;
                            width: 100%
                        }
                        .button.compact-white-nav {
                            padding: 5px;
                            width: 100%
                        }
                    }
                }
                form {
                    margin-top: -3px;
                    button {
                        background: $red;
                        color: $white;
                        border-radius: 25px;
                        margin: 0;
                        padding: 3px 8px;

                    }
                }
            }
        }
    }
}