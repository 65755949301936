#home {
    .intro {
        @include screen {
            margin: 60px 0;
        }
        h2 {
            font-family: $body;
            font-size: 20px;
            font-weight: 400;
            text-transform: uppercase;
            text-align: center;
            line-height: 1.4em!important;
            margin: 0 auto 25px auto;

            @include screen {
                width: 70vw;
                max-width: 1000px;
            }
            @include small-desktop {
                width: 75vw;
            }
            @include touch {
                font-size: 17px;
            }
            @include small-mobile {
                font-size: 16px;
            }
            @include xs-mobile {
                font-size: 15px;
            }
        }
    }
    .splitView {
        position: relative;
        
        @include screen {
            display: flex;
            align-items: center;
        }
        @include touch {
            display: block;
        }
        
        .content {
            width: 50%;
            padding: 60px;
            margin-bottom: 0;
            
            h3 {
                font-size: 36px;
            }
            
            @include desktop {
                padding: 50px;
            }
            @include small-desktop {
                padding: 40px;
            }
            @include mobile {
                padding: 0 30px;
            }
            @include small-mobile {
                padding: 0 20px;
            }
            @include touch {
                width: 100%;
                display: block;
            }
        }
        .image {
            @extend .backgroundSettings;
            
            @include screen {
                width: 50%;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
            }
            
            @include touch {
                width: 100%;
                height: 500px;
                position: relative;
                margin-top: 40px;
            }
        }
    }
    .dark {
        padding: 100px 0;
        
        @include tablet {
            padding: 80px 0;
        }
        @include mobile {
            padding: 70px 0;
        }
        @include small-mobile {
            padding: 60px 0;
        }
    }
    .subtitle {
        font-size: 52px;
        color: $white;
        text-align: center;
        margin-bottom: 90px;
        
        @include tablet {
            margin-bottom: 70px;
        }
        @include mobile {
            margin-bottom: 60px;
        }
        @include small-mobile {
            margin-bottom: 50px;
        }
    }
    .video {
        margin-bottom: 100px;
        
        @include tablet {
            margin-bottom: 80px;
        }
        @include mobile {
            margin-bottom: 70px;
        }
        @include small-mobile {
            margin-bottom: 60px;
        }
    }
    .textBox {
        margin-bottom: 100px;
        
        @include tablet {
            margin-bottom: 80px;
        }
        @include mobile {
            margin-bottom: 70px;
        }
        @include small-mobile {
            margin-bottom: 60px;
        }
        
        p {
            color: $white;
            text-align: center;
            margin: 0 auto;
            
            @include screen {
                width: 60vw;
            }
        }
    }
}