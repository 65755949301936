#contact {
    .contactInfo {
        margin: 50px 0;
        li {
            margin: 10px 0;
            p {
                margin: 0;
                
                span {
                    text-transform: uppercase;
                    color: $red;
                    font-weight: 600;
                }
                a {
                    color: $grey5;
                    
                    &:hover {
                        color: $red;
                    }
                }
            }
        }
    }
    .social {
        margin-bottom: 40px;
    }
}