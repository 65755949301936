input {
    font-family: $body;
}
select {
    font-family: $body;
}
/*.select:not(.is-multiple):not(.is-loading)::after {
    border-color: $black;
}*/
.pagination-link {
    font-family: $body;
}
.pagination-next {
    font-family: $body;
}
.pagination-previous {
    font-family: $body;
}
/*.pagination-link.is-current {
    background-color: $black;
    border-color: $black;
}*/


/*-------------------------
    FORM STYLES
--------------------------*/ 
form {
    
    button {
        display: inline-block;
        text-decoration: none;
        background: none;
        border: none;
        cursor: pointer;
        font-family: $body;
        font-weight: 600;
        font-size: 17px;
        background: $red;
        color: $white;
        text-align: center;
        padding: 12px 25px;
        border-radius: $radius;
        text-transform: uppercase;
        margin-top: 8px;
        @extend .smooth;
        
        &:hover {
            background: #000;
        }
    }
    hr {
        background-color: $greyC;
        height: 1px;
        margin: 10px 0;
    }
    textarea { 
        @extend input;
        height: 120px;
        resize: none;
    }
    
    .ff-form-errors {
        background: $red;
        margin-bottom: 20px;
        p {
            margin-bottom: 0!important;
            color: $white;
            font-size: 15px;
            font-weight: 500;
        }
    }
    
    .freeform-row {
        @include touch {
            display: block;
        }
    }
    
    .freeform-row .freeform-column .freeform-label {
        font-family: $body;
        font-size: 15px!important; 
        color: $grey5!important; 
        font-weight: 500!important; 
        text-transform: none !important; 
        margin-bottom: 6px!important; 
        letter-spacing: .04em!important;

    }

    .hide-label{
        display: none !important;
    }

    .freeform-required::after{
        content: "" !important;
        display: none !important;
    }
    .show-asterisk::after {
        display: inline-block !important;
        content: "*" !important;
        margin-left: 5px !important;
        color: $grey5!important;
    }

    
    .freeform-input {
        font-family: $body;
        color: $grey5!important; 
        font-size: 17px!important;
        background-color: #F1F1F2;
        border: 1px solid #ced4da;
        border-radius: $radius;
        padding: .375rem .75rem;
        @extend .smooth;
        outline: none;
        
        &:hover {
            border-color: $blue!important;
        }
        &:focus {
            border-color: $blue!important;
            background: #D9F4FF!important;
        }
        &.ff-has-errors {
            border-color: #FF1410!important;
            background: #FFDCDB!important;
        }
    }
    .ff-errors>li {
        color: $red;
        font-size: 14px;
    }
    .freeform-input[type=file] {
        background: none!important;
        border: none!important;
        padding: 0!important;
    }
}