.card {
    box-shadow: none;
    border: 1px solid $greyD;
    
    .image {
        @extend .backgroundSettings;
        width: 100%;
        height: 300px;
        @extend .smooth;
    }
    
    .cardContent {
        padding: 30px;
        background: $white;
        
        h4 {
            @extend h3;
            font-size: 32px;
            margin-bottom: 20px;
        }
    }
    h5 {
        font-size: 16px;
        font-weight: 600;
        color: $red;
        text-transform: uppercase;
        text-align: center;
        padding: 20px;
        border-top: 1px solid #DDD;
        letter-spacing: .05em;
        @extend .smooth;
    }
    p {
        color: $grey5!important;
        margin: 0!important;
    }
    
    &:hover {
        .image {
            filter: contrast(1.2);
        }
        h5 {
            border-color: $red;
            //background: #f5f5f5;
        }
    }
}