.contactForm__wrap{
    max-width: 1000px !important;
    margin: auto;
    padding-top: 25px;

    .label-bottom-padding{
        padding-bottom:22px;
        @media (max-width: 1021px) { 
            padding-bottom:0px !important;
         }
    }
    .label-formatting{
        font-family: "Open Sans", sans-serif;
        font-size: 15px !important;
        color: #555 !important;
        font-weight: 500 !important;
        text-transform: none !important;
        margin-bottom: 6px !important;
        letter-spacing: 0.04em !important;
    }
    .label-max-230{
        max-width: 230px;
        @media (max-width: 1021px) { 
            max-width: 100% !important;
         }
    }
}

