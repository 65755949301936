.social {
    p {
        @extend h5;
        font-size: 14px;
        color: $grey5;
        text-transform: uppercase;
        display: inline-block;
        margin-right: 8px;
    }
    ul {
        display: inline-block;
    }
    li {
        display: inline-block;
        margin-right: 4px;

        .orb {
            background: $red;
            border-radius: $round;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            @extend .smooth;
                    
            i {
                color: $white;
                position: relative;
                top: 1px;
            }
            
            &.facebook {
                i {
                    left: -1px;
                }
            }
            &.twitter {
                i {
                    top: 2px;
                }
            }
            &.insta {
                i {
                    left: 0px;
                }
            }
            
            &:hover {
                background: $redAlt;
            }
        }
    }
}