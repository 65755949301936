.carousel {
    margin-bottom: 30px;
    .carousel-inner {
        .carousel-item {
            background-size: cover;
            background-position: 40% bottom;
            text-align: center;
            min-height: 914px;
            padding: 4rem 3rem 4rem;
    
            h2 {
                color: $black;
                font-family: $secondary;
                font-size: 72px;
            }
            .sliderDesc {
                &.text-black * {
                    color: $black !important;
                }
                &.text-red * {
                    color: $red !important;
                }
                &.text-white * {
                    color: $white !important;
                }
            }
            h4 {
                color: $white;
                font-family: $hero;
                font-weight: 300;
                max-width: 60%;
                line-height: 1.2em;
                font-style: italic;
                margin: 0 auto;
                text-transform: inherit;
                &.text-black {
                    color: $black !important;
                }
                &.text-red {
                    color: $red !important;
                }
            }
            @include tablet {
                min-height: 550px;
                h2 {
                    color: $black;
                    font-family: $secondary;
                    font-size: 42px;
                }
                h4 {
                    font-size: 18px;
                }
            }
            @include mobile {
                min-height: 550px;
                padding: 2rem 1rem 3rem;
                h2 {
                    font-size: 36px;
                }
                h4 {
                    font-size: 18px;
                    max-width: 80%;
                }
            }
            @include small-mobile {
                min-height: 550px;
                padding: 2rem 1rem 3rem;
                h2 {
                    font-size: 36px;
                }
                h4 {
                    font-size: 18px;
                    max-width: 80%;
                }
            }
        }
    }
}
.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 50px;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
    li {
        border: 3px solid #fff;
        /* box-sizing: content-box; */
        flex: 0 1 auto;
        width: 24px;
        height: 24px;
        margin-right: 3px;
        margin-left: 3px;
        text-indent: -999px;
        cursor: pointer;
        background-color: transparent;  
        opacity: .5;
        transition: opacity 0.6s ease;
        border-radius: 50%;
        &.active {
            background-color: #FFF;
        }
    }
}
.carousel-control-prev-icon, .carousel-control-next-icon {
    display: inline-block;
    width: 64px;
    height: 74px;
    background-repeat: no-repeat;
    opacity: 100%; 
}
.bannerArrowBand {
    position: absolute;
    max-width: 95px;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
        margin: 0 auto;
    }
}