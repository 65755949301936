.pageBuilder {
    h2 {
        text-transform: inherit;
    }
    .builderContent{
        ul{
            li{
                p {
                    font-family: "Open Sans", sans-serif;
                    font-size: 20px;
                    color: #000 !important;
                }
            }
        }
        &.text-white {
            p, li {
                color: $white !important;
            }
        }
        &.text-red {
            p, li {
                color: $red !important;
            }
        }
    }
    .centered {
        @include screen {
            text-align: center;
        }
    }
    .BGB-full-background{
        background-size: cover;
        background-position: center bottom;
        background-repeat: no-repeat;
        height: 500px; // check
    }
    .headerBelt {
        padding: 40px;
        text-align:center;
        h2 {
            color: $black;
            font-family: $secondary;
            text-transform: uppercase;
            margin-bottom: 15px;
        }
        h3 {
            color: $red;
            font-family: $hero;
            font-size: 1.8em;
            font-weight: 500;
            font-style: italic;
            line-height: 1.5em;
            text-transform: inherit;
        } 
    }
    .productGrid-mobile{
        display:none !important;
        @media (max-width: 1200px) { 
            display:inline-block !important;
         }
         .black-bkg{
             background-color: $black !important;
         }
    }

    .productGrid-desktop{
        display:none !important;

        @media (min-width: 1200px) { 
            display:inline-block !important;
         }
    }
    .productGrid {
        .header {
            background-color: $red;
            color: $white;
            div:nth-child(2) {
                background-color: $black;
            }
            h4 {
                font-family: $bebas;
                font-weight: 300;
                color: $white;
                margin: 0;
                padding: 15px 0;
            }
        }
        .productContent {
            font-family: $body;
            padding: 20px 0;
            .nutritionLine {
                &.nutritionHeader {
                    color: $red;
                    font-weight: 600;
                }
                span {
                    text-align: center;
                    &:first-child {
                        text-align: left;
                        min-width: 42%;
                        width: 42%;
                    }
                    &:last-child {
                        
                    }
                }
                font-size: 1em;
                border-bottom: solid 1px #000;
                padding-bottom: 15px;
            }
        }
    }
    .skinnyBelt {
        color: $white;
        h5 {
            color: $white;
            font-family: $hero;
            font-size: 1.5em;
            font-style: italic;
            margin: 0;
            padding: 10px;
            a {
                color: #fff;
                border-bottom: solid 1px #fff;
            }
        }
        &.green {
            background-color: $green;
        }
        &.blue {
            background-color: $blue;
        }
        &.orange {
            background-color: $orange;
        }
        &.red {
            background-color: $red;
        }
    }
    .builderSection {
        .row {
            .col-md {
                padding: 0;
            }
            .prodSelect {
                min-height: 400px;
                vertical-align:bottom;
                img {
                    display: flex;
                    vertical-align:bottom;
                    min-width: 100%;
                }
                .prodSelectContent{
                    background-size: cover;
                    height: 100%;
                    min-height: 380px;
                    width: 100%;
                    background-repeat: no-repeat;
                    &.colBGgreen {
                        background-color: $green;
                        background-size: auto 100%;
                        background-position: center bottom;
                    }
                    &.colBGorange {
                        background-color: $orange;
                    }
                    &.colBGblue {
                        background-color: $blue;
                    }
                }
            }
        }
        background-size: cover;
        &.bg-contain {
            background-size: contain !important;
            background-repeat: no-repeat;
        }
        &.bg-cover {
            background-size: cover !important;
            background-repeat: no-repeat;
        }
        &.bottomLeft {
            background-position: left bottom;
        }
        &.bottomRight {
            background-position: right bottom;
        }
        &.bottomCenter {
            background-position: center bottom;
        }
        &.centerLeft {
            background-position: left center;
        }
        &.centerRight {
            background-position: right center;
        }
        &.centerCenter {
            background-position: center center;
        }
        &.topLeft {
            background-position: left top;
        }
        &.topRight {
            background-position: right top;
        }
        &.topCenter {
            background-position: center top;
        }
        h2,h3,h4,h5,h6 {
            color: $black;
            text-align: center;
            font-family: $bebas;
            font-size: 32px;
            font-weight: 300;
            margin-bottom: 0;
            padding: 10px 0;
            &.text-red {
                color: $red;
            }
            &.text-white {
                color: $white;
            }
            &.text-black {
                color: $black;
            }
        }
        h2 { 
            font-family: $bebas;
            font-size: 42px;
        }
        h3 { 
            font-family: $bebas;
            font-size: 32px;
            font-weight: 200;
        }
        h4 { 
            font-family: $bebas;
            text-transform: uppercase;
            font-size: 32px;
        }
        .builderHeader {
            text-align: center;
        }
        .list {
            ul {
                padding: 0;
                list-style: none;
                li {
                    font-size: 16px;
                    font-weight: 600;
                    padding-left: 60px;
                    min-height: 50px;
                    background: url(/assets/img/starburst.png) left center no-repeat;
                }
            }
        }
        img {
            display: block;
            margin: 0 auto;
        }
        h3 {
            color: $red;
            font-family: $hero;
            font-size: 1.8em;
            font-weight: 500;
            font-style: italic;
            line-height: 1.5em;
            margin-bottom: 20px;
            text-align: left;
            text-transform: inherit;
        } 
        .button {
            .light {
                border: 2px soild $red;
            }
        }
    }
    .fluidGrid {
        text-align: center;
        .row.halfHeight {
            min-height: 17rem;
            .col-md {
                background-size: cover;
            }
            .gridColContent {
                padding: 40px;
            }
        }
        .col-md {
            &.fullHeight { 
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center bottom;
                display: flex;
                min-height: 34rem;
                position: relative;
                /* Original 
                background-size: contain;
                background-repeat: no-repeat;
                height: 550px; 
                position: relative; */
                .gridColContent {
                    text-align: left;
                    padding: 40px;
                }
                @include tablet {
                    min-height: 20rem;
                    .gridColContent {
                        h3 {
                            font-size: 26px;
                        }
                    }
                }
                @include mobile {
                    min-height: 20rem;
                    .gridColContent {
                        h3 {
                            font-size: 26px;
                        }
                    }
                }
                @include small-mobile {
                    min-height: 20rem;
                    .gridColContent {
                        max-width: 100%;
                        h3 {
                            font-size: 26px;
                        }  
                    }
                }
                &.halfHeight {
                    .col-md {
                        background-size: cover;
                    }
                }
            }
            &.halfHeight {
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center bottom;
                min-height: 17rem;
                position: relative;
                text-align: left;
                .col-md {
                    background-size: cover;
                }
            }
        }
        
        h3 {
            color: $white;
            font-family: $hero;
            font-style: italic;
            font-size: 40px;
            font-weight: 500;
            text-transform: initial;
        }
        p {
            color: $white;
            font-weight: 400;
        }
    }
    .dark {
        @extend .pagePadding;
    }
    .lightGrey {
        @extend .pagePadding;
    }
    .relatedContent {
        h2 {
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 40px;
        }
        &.dark {
            h2 {
                color: $white;
            }
            p {
                color: $white;
            }
        }
    }
    
    .body {
        margin-bottom: -30px;
        p {
            margin-bottom: 30px;
        }
        ul {
            margin-bottom: 20px;
            
            @include touch {
                margin-bottom: 30px;
            }
        }
        &.twoColumn {
            column-count: 2;
            column-gap: 40px;
            
            @include touch {
                column-count: 1;
            }
        }
        h1, h2, h3, h4, h5, h6 {
            color: $grey5;
        }
        h1 {
            @extend h2;
            font-size: 65px!important;
            margin-bottom: 30px!important;
            
            @include tablet {
                font-size: 62px;
            }
            @include mobile {
                font-size: 58px;
            }
            @include small-mobile {
                font-size: 54px;
            }
        }
        h2 {
            font-size: 48px;
            margin-bottom: 30px;
            
            @include tablet {
                font-size: 44px;
            }
            @include mobile {
                font-size: 42px;
            }
            @include small-mobile {
                font-size: 40px;
            }
        }
        h3 {
            font-size: 40px;
            margin-bottom: 30px;
            
            @include tablet {
                font-size: 38px;
            }
            @include mobile {
                font-size: 36px;
            }
            @include small-mobile {
                font-size: 34px;
            }
        }
        h4 {
            @extend h2;
            font-size: 32px;
            margin-bottom: 30px;
            
            @include tablet {
                font-size: 30px;
            }
            @include mobile {
                font-size: 28px;
            }
            @include small-mobile {
                font-size: 28px;
            }
        }
        h5 {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 22px;
            margin-bottom: 20px;
            
            @include mobile {
                font-size: 20px;
            }
            @include small-mobile {
                font-size: 20px;
            }
        }
        h6 {
            @extend h5;
            font-size: 16px;
            margin-bottom: 15px;
            
            @include mobile {
                font-size: 15px;
            }
            @include small-mobile {
                font-size: 14px;
            }
        }
    }
    
    .image {
        position: relative;
        margin: 0 auto;
        
        @include screen {
            width: 80%;
        }
        img {
            border-radius: $radius;
            margin: 0;
        }
        
        &.small {
            @include screen {
                width: 50%;
            }
        }
    }
    
    .splitView {
        padding: 0!important;
        
        @include screen {
            display: flex;
            align-items: center;
        }
        @include touch {
            display: block;
        }
        
        &.lightGrey {}
        &.black {
            background: $grey2;
            h3 {
                color: $white;
            }
            p {
                color: $white;
            }
        }
        &.dark {
            background: $grey4!important;
            h3 {
                color: $white;
            }
            p {
                color: $white;
            }
        }
        &.textLeft {
            flex-flow: row;
        }
        &.textRight {
            flex-flow: row-reverse;
        }
        
        .content {
            width: 50%;
            padding: 50px;
            margin-bottom: 0;
            
            .uk-divider-small {
                &:after {
                    width: 60px;
                    border-top: 5px solid $red;
                }
            }
            h3 {
                font-size: 36px;
                margin-top: 30px
            }
            @include touch {
                padding: 40px 30px;
                width: 100%;
                display: block;
            }
        }
        .uk-overflow-hidden {
            width: 50%;
            
            @include touch {
                width: 100%;
            }
        }
    }
    
    .gridList {
        h2 {
           margin-bottom: 30px;
        }
        h3 {
            background-color: $greyE;
            color: $grey5;
            font-size: 22px;
            font-weight: 600;
            padding: 10px 15px;
            text-align: center;
        }
        
        &.dark {
            h2 {
                color: $white!important;
            }
            h3 {
                background-color: $grey4;
                color: $white;
            }
        }
        &.lightGrey {
            h3 {
                background-color: $greyD;
                color: $grey6;
            }
        }
    }
    
    .fancyList {
        @include screen {
            text-align: left;
            display: inline-block;
        }
    }
    
    .heroImage {
        width: 100%;
        height: 800px;
        @extend .backgroundSettings;
        
        @include small-desktop {
            height: 650px;
        }
        @include tablet {
            height: 600px;
        }
        @include mobile {
            height: 450px;
        }
        @include small-mobile {
            height: 400px;
        }
    }
    
    .leadForm {
        h4 {
            @extend h2;
            margin-bottom: 10px;
        }
        @include screen {
            width: 60vw;
            margin: 0 auto;
            
            h4 {
                text-align: center;
            }
            p {
                text-align: center;
                width: 75%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    
    .spacer { 
        .divider {
            margin: 60px auto;
            
            @include mobile {
                margin: 50px auto;
            }
            @include small-mobile {
                margin: 40px auto;
            }
            hr {
                border-top: 1px solid $greyD;
            }
        }
        .standard {
            width: 100%;
            height: 60px;
            display: block;
            
            @include mobile {
                height: 50px;
            }
            @include small-mobile {
                height: 40px;
            }
        }
        .large {
            width: 100%;
            height: 100px;
            display: block;
            
            @include mobile {
                height: 75px;
            }
            @include small-mobile {
                height: 55px;
            }
        }
        .small {
            width: 100%;
            height: 30px;
            display: block;
            
            @include mobile {
                height: 20px;
            }
            @include small-mobile {
                height: 20px;
            }
        }
    }
}
// Page Banners 
.pageBanner {
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 280px;
    padding:25px 25px 0;
    .multiColumn {
        .button {
            display: block;
            margin: 20px auto 0;
        }
    }
    &.skinny {
        min-height: 160px;
    }
    &.regular {
        min-height: 275px !important;
    }
    &.bg-cover {
        background-size: cover;
    }
    &.bg-contain {
        background-size: contain;
        background-position: center bottom;
        background-repeat: no-repeat;
    }
    &.bg-green {
        background-color: $green;
    }
    &.bg-blue {
        background-color: $blue;
    }
    &.bg-red {
        background-color: $red;
    }
    &.bg-orange {
        background-color: $orange;
    }
    
    h2 {
        color: white;
        font-size: 50px;
        margin-bottom: 10px;
        &.text-white {
            color: white;
        }
        &.text-black {
            color: $black;
        }
        &.text-red {
            color: $red;
        }
        &.text-oswald {
            font-family: $secondary;
        }
        &.text-crete {
            font-family: $hero;
        }
        &.text-bebas {
            font-family: $bebas;
            font-weight: 300;
        }
        &.text-lowercase {
            text-transform: inherit !important;
        }
    }
    h3 {
        color: white;
        font-family: $hero;
        font-size: 30px;
        &.text-white {
            color: white;
        }
        &.text-black {
            color: $black;
        }
        &.text-red {
            color: $red;
        }
        &.text-oswald {
            font-family: $secondary;
        }
        &.text-crete {
            font-family: $hero;
        }
        &.text-bebas {
            font-family: $bebas;
            font-weight: 300;
        }
    }
    p {
        color: #FFF;
        &.text-white {
            color: white;
        }
        &.text-black {
            color: $black;
        }
        &.text-red {
            color: $red;
        }
    }
    .imageContainer {
        height: 100%;
        width: 100%;
        .img-fluid {
            vertical-align:bottom;
        }
    }
    .button {
        text-transform: inherit;
        font-weight: 300;
    }
}