.searchInput {
    border: $red solid 2px;
    border-radius: 10px;
    padding: 2px 5px;
    transition: $transition;
    
    @include tablet {
        max-width: 120ox
    }

    &:hover {
        
    }
    &:focus {
       
    }
}
.searchInput::placeholder {
    color: $grey5;
    font-family: $secondary;
}
.control {
    i {
        color: $red;
    }
}
#searchResults {
    margin-top: 50px;
    
    @include screen {
        padding-right: 60px;
    }
    .result {
        border-bottom: 1px solid $greyD;
        margin-bottom: 30px;
        padding: 10px 0 35px;
        transition: $transition;
        
        &:last-of-type {
            border-bottom: none;
            margin-bottom: 0;
        }
        h4 {
            margin-bottom: 10px;
            transition: $transition;
            
            &:hover {
                color: $red;
            }
        }
    }
    .noResults {
        display: none;
        i {
            color: $greyD;
            font-size: 3em;
            padding: 20px;
        }
        h3 {
            margin-bottom: 20px;
        }
        a {
            color: $red;
        }
    }
}
.featImg {
    margin-bottom: 30px;
}
.searchSidebar {
    border: solid 1px $greyD;
    li {
        border-bottom: solid 1px $greyD;
        font-weight: 400;
        padding: 15px;
        font-size: 20px;
        
        a {
            color: $grey5;
            
            &:hover {
                color: $red;
            }
        }
    }
    li:last-of-type {
        border-bottom: none;
    }
}

.search-title {
    color: #1a1a1a !important;
    font-family: "Oswald",sans-serif;
    font-size: 72px !important;
    font-weight: 500;
    line-height: 1.2em!important;
    margin-bottom: 20px!important;
    text-transform: uppercase;
}

.result-title {
    a {
        color: $grey5;
        
        &:hover {
            color: $red !important;
        }
    }

    font-family: "paralucent-condensed", sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $grey5;
    text-transform: uppercase;
    line-height: 1em !important;

    &:hover {
        color: $red !important;
    }
}