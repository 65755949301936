
.salesReps {
    background: url(/assets/img/BGB_USAmap.png);
    background-repeat:no-repeat; 
    background-position: right top !important;
    width:100%;
	padding: 2rem 0;
	width: 100%;
    z-index: 20;
    
    .headerBelt {
        padding: 40px;
        text-align:center;
        h2 {
            color: $black;
			font-family: $secondary;
			padding-top: 20px;
			text-transform: uppercase;
        }
        h3 {
            color: $red;
            font-family: $hero;
            font-size: 1.8em;
            font-weight: 500;
            font-style: italic;
            line-height: 1.5em;
            text-transform: inherit;
        } 
	}
	#salesReps__section {
		background: $red;
		color: #FFF;
		position: absolute;
		margin-top: -60px;
		width: 100% !important;
		max-width: 400px;
		div.region {
			display: none;
		}
		div.dealerInfo {
			p {
				font-size: .95em;
			}
		}
		
		@include touch {
			position: inherit;
			margin-top: 60px;
		}
	}

}

@media (min-width:768px) {
	.salesReps {
		padding: 0
	}
}

.salesReps__title {
	font-weight: 900;
	font-size: 1.25rem;
	text-transform: uppercase
}

.salesReps__summary {
	margin-bottom: .5rem
}

#sales-region {
	width: 75%
}

.salesReps__section__title {
	font-weight: 700;
	font-size: 1.25rem
}

.salesReps__chosenRegion {
	color: #B4111C;
}

.salesReps__info {
	background: rgba(0, 0, 0, .6);
	color: #fff;
    padding: 1rem 1.5rem
}

.salesReps__info a {
	color: #fff
}

.salesRep__type {
	color: #fff;
	font-weight: 700
}

.salesRep__name,
.salesRep__phoneNumber,
.salesRep__website,
.salesRep__email {
	font-size: .875rem
}

.salesRep__website a {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center
}

.salesRep__website a .svg-inline--fa {
	margin-left: .25rem
}

.contact-link-style{
	a, a:hover, a:focus, a:active {
		text-decoration: none;
		color: inherit;
   }
   a:link, a:visited {
    color: inherit !important;
}
}