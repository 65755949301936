.pressBuilder {
    @extend .pageBuilder;
    
    @include screen {
        width: 60vw;
        margin: 0 auto;
    }
    @include desktop {
        width: 65vw;
        margin: 0 auto;
    }
    @include small-desktop {
        width: 75vw;
        margin: 0 auto;
    }
    
    .titleRow {
        margin: 60px 0;
        
        @include handheld {
            margin: 50px 0;
        }
        
        .date {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 17px;
            letter-spacing: .06em;
            color: $grey9!important;
        }
        h1 {
            @extend h2;
            font-size: 48px !important;
            color: $grey5;
            margin-bottom: 20px!important;
            
            @include tablet {
                font-size: 44px !important;
            }
            @include handheld {
                font-size: 40px !important;
            }
        }   
    }
}

.relatedReads {
    padding: 80px 0;
    margin-top: 80px;
    
    @include small-desktop {
        padding: 70px 0;
        margin-top: 70px;
    }
    @include tablet {
        padding: 60px 0;
        margin-top: 60px;
    }
    @include mobile {
        padding: 50px 0;
        margin-top: 50px;
    }
    @include small-mobile {
        padding: 40px 0;
        margin-top: 40px;
    }
    
    
    .subtitle {
        font-size: 40px!important;
        margin-bottom: 40px!important;
        
        @include touch {
            font-size: 36px;
        }
    }
}