h1,h2,h3,h4,h5,h6,p {
    //margin: 0;
    //padding: 0;
}
h1,h2,h3,h4,h5,h6 {
    line-height: 1.2em;
    font-variant-numeric: lining-nums;
}
h1 { 
    font-family: $hero;
    font-size: 96px!important;
    font-weight: 400;
    color: $white;    
    line-height: .8em!important;
    text-transform: lowercase;
    margin: 0 auto!important;
    
    strong, b {
        color: $red;
    }
    
    @include small-desktop {
        font-size: 85px!important;
    }
    @include tablet {
        font-size: 75px!important;
    }
    @include mobile {
        font-size: 62px!important;
    }
    @include small-mobile {
        font-size: 62px!important;
    }
    @include xs-mobile {
        font-size: 55px!important;
    }
} 
h2 { 
    font-family: $primary;
    font-size: 42px;
    font-weight: 600;
    color: $grey5;
    line-height: 1em!important;
    margin-bottom: 30px;
    
    strong, b {
        color: $red;
    }
    
    @include mobile {
        font-size: 40px;
    }
    @include small-mobile {
        font-size: 36px;
    }
    @include xs-mobile {
        font-size: 34px;
    }
} 
h3 { 
    font-family: $primary;
    font-size: 36px;
    font-weight: 600;
    color: $grey5;
    text-transform: uppercase;
    line-height: 1em!important;
    
    strong, b {
        color: $red;
    }
    
    @include small-mobile {
        font-size: 34px;
    }
    @include xs-mobile {
        font-size: 32px;
    }
} 
h4 { 
    font-family: $primary;
    font-size: 32px;
    font-weight: 600;
    color: $grey5;
    text-transform: uppercase;
    line-height: 1em!important;
    
    @include small-mobile {
        font-size: 30px;
    }
    @include xs-mobile {
        font-size: 28px;
    }
}
h5 { 
    font-family: $body;
    font-size: 24px;
    font-weight: 500;
    color: $grey5;
    letter-spacing: .02em;
    
    @include small-mobile {
        font-size: 22px;
    }
    @include xs-mobile {
        font-size: 20px;
    }
}
p { 
    font-family: $body;
    font-size: 20px;
    color: #000;
    
    @include small-mobile {
        font-size: 18px;
    }
    @include xs-mobile {
        font-size: 16px;
    }
    
    //Inline Links
    a {
        color: $red;
        
        &:hover {
            color: $redAlt;
        }
    }
    &.text-white {
        color: $white;
    }
    &.text-red {
        color: $red;
    }
}
.textbox {
    p {
        margin-bottom: 25px;
        
        @include xs-mobile {
            margin-bottom: 20px;
        }
    }
}
.body {
    ul {
        //padding-left: 20px;
        
        li {
            //list-style-type: square;
            font-size: 18px;
            margin-bottom: 6px;
            
            @include small-mobile {
                font-size: 16px;
            }
            
            &:before {
                content: "â";
                color: $red;
                display: inline-block;
                margin-right: 8px;
                font-size: 16px;
                position: relative;
                top: -2px;
                
                @include small-mobile {
                    font-size: 14px;
                    top: -2px;
                }
            }
        }
    }
}
.subtext {
    p {
        font-size: 16px;
        margin: 30px 0;
    }
}
//Base Links
a:link {
    color: $blue;
    text-decoration: none!important;
    @extend .smooth;
}
a:visited {
    color: $blue;
}
a:hover {
    color: $red;
}
a:active {
    color: $blue;
}
.text-red {
    color: $red !important;
}