.whereToBuy{
background-color: #fff;

.form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #F1F1F2;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    max-width:500px !important;
    margin:auto;
    margin-bottom:50px;
}
.logo-icon{
    margin:15px;
    max-width: 170px;
}

.national-logo-container{
    background-color:#FAF4E4;
}
.inner-logo-container{
    padding: 30px;
    max-width: 1200px;
    text-align: center;
    margin: auto;
}

.regional-logo-container {
    background-color:#FFF;
    .region {
        display: none;
    }
}
.blue-band {
    padding: 20px;
    text-align:center;
    background-color: #14AFDD;
    h2 {
        color: $black;
        font-family: $secondary;
    }
    h3 {
        color: #fff;
        font-family: $hero;
        font-size: 1.8em;
        font-weight: 500;
        font-style: italic;
        line-height: 1.5em;
        text-transform: inherit;
    } 
}

.green-band {
    padding: 40px;
    text-align:center;
    background-color: #75AC46;
    h2 {
        color: $black;
        font-family: $secondary;
    }
    h3 {
        color: #fff;
        font-family: $hero;
        font-size: 1.8em;
        font-weight: 500;
        font-style: italic;
        line-height: 1.5em;
        text-transform: inherit;
    } 
}

.dropdown-band{
    margin:auto;
}
.headerBelt {
    padding: 40px;
    text-align:center;
    h1{
        color: $black;
        font-family: $secondary;
        color: #1A1A1A;
        font-family: "Oswald", sans-serif;
        font-size: 72px !important;
        font-weight: 500;
        line-height: 1.2em !important;
        margin-bottom: 20px !important;
        text-transform: uppercase;

    }
    h2 {
        color: $black;
        font-family: $secondary;
    }
    h3 {
        color: $red;
        font-family: $hero;
        font-size: 1.8em;
        font-weight: 500;
        font-style: italic;
        line-height: 1.5em;
        text-transform: inherit;
    } 
}

}