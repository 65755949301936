footer {
    background: #FFF;
    .footer-heading{
        padding-top:6px;
        font-size:20px;
        font-weight:700;
        font-family: "Crete Round", serif;
    }

    .mainFooter__address {
        font-size: 16px;
        text-align: center;
        margin-bottom:0px;
        padding-top:10px;
        line-height: 1.3 !important;
    }
    .mainFooter__phoneNumber {

        text-align: center;
        line-height: 1.3 !important;
    }

    .broker-portal{
        font-family: "Oswald", sans-serif;
        font-size: 1em !important;
        color: #FFF;
        text-transform: uppercase;
        text-decoration: underline;
        padding-top: 10px;
    }

    .right-logo{
        padding-top:0px !important;

    }
    .row {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .a{
            color: #B4111C;
            font-weight: 200;
    }
    .list-inline {
        padding-left: 0;
        list-style: none;
        margin-bottom: 3px;
    }
    .footer-container{
        padding-top:30px;
        padding-bottom:30px;
    }
    .footer-nav {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row;
        flex-flow: row;
    }

    .footer-nav>.nav>.nav-item>.footer-nav-link {
        font-size:17px;
        color: #1A1A1A;
        font-family: 'Oswald',sans-serif;
        font-weight: 500;
        text-transform: uppercase;
        padding:10px;
        padding-bottom:5px;

    }
    .footer-nav>.nav>.nav-item>.footer-nav-link a{
        font-size: 16px;
        color: #000;
        font-family: 'Oswald', sans-serif;
        font-weight: 500;
        text-transform: uppercase
    }
    .mainFooter__nav {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    }
    .mainFooter__navPadding{
        padding-left: 30px;
    }
    
    .mainFooter__navSection {
        float: left;
        padding: 0 10px;
    }
    
    .mainFooter__navSection:first-child {
        padding-left: 0
    }

    .mainFooter__navSection:last-child {
        padding-right: 0
    }
    .mainFooter__navTitle{
        line-height:1 !important;
        margin-bottom: 5px;
    }

    .mainFooter__navTitle a {
        color: #1A1A1A;
        font-size: 17px;
        font-family: 'Oswald', sans-serif;
        font-weight: 500;
        text-transform: uppercase;
        line-height:1 !important;

    }
    
    .mainFooter__link a {
        color: #4d4d4d;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-weight: 400;

    }
    .footer-nav-link {
        display: block;
        padding: 2px;
        padding-left: 10px;
        padding-right: 10px;
        line-height: 1;
    }
    
    .mainFooter__link a:hover {
        color: #B4111C
    }
    
    .mainFooter__subNavSection {
        float: left;
        padding: 0 1rem
    }
    
    .mainFooter__subNavSection:first-child {
        padding-left: 0
    }
    
    .mainFooter__subNavSection:last-child {
        padding-right: 0
    }
    
    .footerContent {
        background-color: #B4111C;
        color: #fff;
        .column {
            position: relative;
            
            .lead {
                padding: 30px;
                
                @include handheld {
                    padding: 60px 30px 30px 30px;
                }
            }
        }  
        .footer-social-icons{
        font-size:14px;
        } 
        li {
            color: $white;
            font-size: 16px;
            line-height: 1.8em;
            
            a {
                color: $white;
                
                &:hover {
                    color: $red;
                }
            }
        }
        
        h5 {
            font-size: 18px;
            font-weight: 600;
            color: $grey9;
            text-transform: uppercase;
            margin-bottom: 10px;
        }
        p {
            color: $white!important;
        }
        
        .contactInfo {
            margin: 20px 0;
            font-size: .875rem;
            text-align: center
        }
        
        @include handheld {
            text-align: center
        }
        
    }
    .copyright {
        background: $white;
        color: #4d4d4d;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 400;
        
        a {
            color: $red;
            
            &:hover {
                color: $black;
            }
        }
        p {
            font-size: 12px ;
            font-weight: 500;
            
            span {
                color: $greyD;
                margin: 0 8px;
            }
        }
        .level-left, .level-right {
            @include handheld {
                margin: 0;
                text-align: center;
            }
        }
        .level-right {
            a {
                margin-left: 4px;
            }
        }
    }
}