.breadcrumb {
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    color: $grey9;
    border-bottom: 1px solid $greyD;
    padding: 20px 0;
    
    ul {
        justify-content: center;
    }
    a {
       color: $grey9;
        
        &:hover {
            color: $red;
        }
        &.is-active {
            color: $grey9!important;
            
            &:hover {
                color: $grey9!important;
            }
        }
    }
}