.hero {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    min-height: 350px;
    text-align: center;
    @extend .backgroundSettings;
    
    .content {
        position: relative;
        z-index: 300;
        margin: 0 auto;
        padding: 50px 30px 60px;
        
        @include small-desktop {
            max-width: 65vw;
        }
        @include small-mobile {
            padding: 0 20px;
        }
        
        h1 {
            color: $black;
            font-family: $secondary;
            font-size: 72px;
            font-weight: 500;
            line-height: 1.2em !important;
            margin-bottom: 20px !important;
            text-transform: uppercase;
            @include tablet {
                font-size: 62px !important;
            }
            @include mobile {
                font-size: 52px !important;
            }
        }
        h3 {
            color: $white;
            font-family: $hero;
            text-transform: initial;
            font-style: italic;
            font-size: 30px;
            font-weight: 400;
            @include tablet {
                font-size: 24px;
            }
            @include mobile {
                font-size: 18px;
            }
            &.text-black {
                color: $black !important;
            }
            &.text-red {
                color: $red !important;
            }
        }
        // Defaults
        .heroText {
            max-width: 1050px;
            margin-left: auto;
            margin-right: auto;
            h1,h2,h3,h4,h5,h6 {
                font-family: $hero;
                font-weight: 400;
            }
            &.text-white * {
                color: $white !important;
            }
            &.text-black * {
                color: $black !important;
            }
            &.text-red * {
                color: $red !important;
            }
        }
        .buttonSection {
            margin-top: 30px;
            
            @include handheld {
                 margin-top: 20px;
            }
        }
        .button {
            min-width: 175px;
            &:nth-of-type(2) {
                background: rgba(255, 255, 255, 0.15);

                &:hover {
                    background: rgba(255, 255, 255, 0.1);
                }
            }
            @include xs-mobile {
                min-width: 70vw;
                margin-left: 0!important;
                margin-right: 0!important;
            }
        }
    }
    &.green {
        background-color: $green;
    }
    &.blue {
        background-color: $blue;
    }
    &.orange {
        background-color: $orange;
    }
    &.red {
        background-color: $red;
    }
    .heroFooter {
        position: relative;
        z-index: 200;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 30px;
        
        .geo {
            border-top: 3px solid rgba(255, 255, 255, 0.3);
            border-left: 3px solid rgba(255, 255, 255, 0.3);
            border-right: 3px solid rgba(255, 255, 255, 0.3);
            padding: 10px;
            display: inline-block;
            @extend .smooth;
            
            p {
                @extend h3;
                font-size: 22px;
                display: inline-block;
                color: $white;
                padding: 0 10px;
            }
            i {
                border-left: 3px solid rgba(255, 255, 255, 0.3);
                display: inline-block;
                color: rgba(255, 255, 255, 0.5);
                padding: 10px 10px 10px 20px;
                @extend .smooth;
            }
            &:hover {
                background: rgba(255, 255, 255, 0.08);
                i {
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
        .aboutNav {
            padding: 30px 0;
            li {
                @extend h5;
                font-size: 15px;
                font-weight: 600;
                color: $white;
                text-transform: uppercase;
                display: inline-block;
                margin: 0 20px;
                
                a {
                    color: $white;
                    
                    &:hover {
                        opacity: .5;
                    }
                }
            }
        }
        @include touch {
            display: none;
        }
    }
    .bar {
        width: 100%;
        height: 14px;
        background: rgba(255, 255, 255, 0.2);
        position: absolute;
        left: 0;
        right: 0;
        z-index: 300;
        
        &.top {
            top: 0;
        }
        &.bottom {
            bottom: 0;
        }
    }
    
    &.home {
        height: 75vh;
        min-height: 600px;
        
        @include tablet {
            height: unset;
            min-height: 700px;
        }
        @include mobile {
            height: unset;
            min-height: 600px;
        }
        @include small-mobile {
            height: unset;
            min-height: 550px;
        }
        @include xs-mobile {
            height: unset;
            min-height: 500px;
        }
        
        .content {
            position: relative;
            top: -30px;
            
            @include touch {
                top: 0;
            }
        }
        
        @include small-mobile {
            h1 {
                font-size: 48px!important;
            }
        }
    }
    &.company {
        height: 50vh;
        min-height: 400px;
        
        @include tablet {
            height: unset;
            min-height: 600px;
        }
        @include mobile {
            height: unset;
            min-height: 500px;
        }
        @include small-mobile {
            height: unset;
            min-height: 400px;
        }
        @include xs-mobile {
            height: unset;
            min-height: 350px;
        }
        
        .content {
            position: relative;
            top: -30px;
            
            @include touch {
                top: 0;
            }
        }
    }
    &.large {
        height: 50vh;
        min-height: 400px;
        
        @include tablet {
            height: unset;
            min-height: 600px;
        }
        @include mobile {
            height: unset;
            min-height: 500px;
        }
        @include small-mobile {
            height: unset;
            min-height: 400px;
        }
        @include xs-mobile {
            height: unset;
            min-height: 350px;
        }
    }
    &.small {
        height: 30vh;
        min-height: 240px;
        
        @include touch {
            height: unset;
            min-height: 300px;
        }
    }
    &.lead {
        height: 65vh;
        min-height: 600px;
        
        @include tablet {
            height: unset;
            min-height: 600px;
        }
        @include mobile {
            height: unset;
            min-height: 500px;
        }
        @include small-mobile {
            height: unset;
            min-height: 450px;
        }
        @include xs-mobile {
            height: unset;
            min-height: 400px;
        }
    }
    &.image {
        height: 50vh;
        min-height: 400px;
        
        @include tablet {
            height: unset;
            min-height: 450px;
        }
        @include mobile {
            height: unset;
            min-height: 350px;
        }
        @include small-mobile {
            height: unset;
            min-height: 350px;
        }
        @include xs-mobile {
            height: unset;
            min-height: 300px;
        }
        
        .overlay {
            display: none;
        }
        .content {
            display: none;
        }
    }
}
.cropFix{
    background-size: auto 350px !important;
    @media (max-width: 992px) {
        margin-top: 25px;
    }
}
.defaultNoFix{
    background-size:cover;
}