/*----BUTTONS----*/
.button {
    display: inline-block;
    font-family: $hero;
    position: relative;
    text-decoration: none;
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    height: auto;
    cursor: pointer;
    padding: 0;
    @extend .smooth;
    
    &.light {
        font-family: $hero;
        font-weight: 300;
        font-size: 17px;
        background: $white;
        color: $red;
        text-align: center;
        padding: 12px 25px;
        margin: 30px 10px 30px 0;
        border-radius: $radius;
        border: solid 2px $red;
        @include handheld {
            font-size: 15px;
            padding: 10px 20px;
        }
        
        &:hover {
            background: $black;
            color: $white;
            border: solid 2px $black;
        }
    }
    &.compact {
        font-family: $hero;
        font-weight: 300;
        font-size: 17px;
        font-style:italic;
        background: White;
        color: $red;
        text-align: center;
        padding: 5px 20px;
        margin: 8px 0px 8px 0;
        border-radius: $radius;
        
        @include handheld {
            font-size: 15px;
            padding: 10px 20px;
        }
        
        &:hover {
            background: $black;
            color: $white;
        }
    }  
    
    &.compact-white-nav {
        font-family: $hero;
        font-weight: 300;
        font-size: 17px;
        font-style:italic;
        background: #fff;
        color: #B5121B !important;
        text-align: center;
        margin: 8px 37px 8px 0;
        border-radius: $radius;
        text-transform:none;
        border-color: #B5121B !important;
        border-radius: 1px;
        border: solid 2px #B5121B !important;
        padding: 5px !important;
        margin-top: 0px;
        margin-bottom: 0px;
        min-width: 195px;
        
        @include handheld {
            font-size: 15px;
            padding: 10px 20px;
        }
        
        &:hover {
            background: $black;
            color: $white !important;
            border: solid 2px $black !important;
        }
    } 
    
    &.compact-red-nav {
        font-family: $hero;
        font-weight: 300;
        font-size: 17px;
        font-style:italic;
        background: $red;
        color: #fff;
        text-align: center;
        padding: 5px 55px;
        margin: 8px 37px 8px 0;
        border-radius: $radius;
        text-transform:none;
        
        @include handheld {
            font-size: 15px;
            padding: 10px 20px;
        }
        
        &:hover {
            background: $black;
            color: $white;
            border-bottom: none;
        }

        
    } 


    
    &.primary {
        font-family: $hero;
        font-weight: 600;
        font-size: 17px;
        background: $red;
        color: $white;
        text-align: center;
        padding: 12px 25px;
        margin: 10px 10px 10px 0;
        @include handheld {
            font-size: 15px;
            padding: 10px 20px;
        }
        
        &:hover {
            background: $black;
            color: $white;
        }
    }
    &.primaryRed {
        font-family: $hero;
        font-weight: 600;
        font-size: 17px;
        font-style: italic;
        background: $red;
        color: $white;
        text-align: center;
        padding: 12px 25px;
        margin: 10px 10px 10px 0;
        
        @include handheld {
            font-size: 15px;
            padding: 10px 20px;
        }
        
        &:hover {
            background: $black;
            color: $white;
            border-bottom: none;
        }
    }
    &.icon {
        font-size: 16px;
        i {
            color: $red;
            margin-right: 4px;
            @extend .smooth;
        }
        span {
            color: $grey5;
            font-weight: 600;
            text-transform: uppercase;
            @extend .smooth;
        }
        
        @include handheld {
            font-size: 15px;
        }
        
        &:hover {
            i {
                color: $black;
            }
            span {
                color: $red;
            }
        }
    }
    &.contact {
        font-family: $body;
        font-weight: 600;
        font-size: 17px;
        background: $grey2;
        color: $white;
        text-align: center;
        padding: 12px 25px;
        margin: 0;
        border-radius: $radius;
        text-transform: uppercase;
        
        @include handheld {
            font-size: 15px;
            padding: 10px 20px;
        }
        
        &:hover {
            background: $black;
        }
    }
}