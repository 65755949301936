.products {
    .hero {
        .content {
            h1 {
                @include handheld {
                    padding-top: 60px;
                }
            }
        }
    }
    .productNav {
        margin-left: -4%;
        position: absolute;
        top: 15px;
        z-index: 301;
        li {
            border-right: 1px #000 solid;
            &:last-child {
                border-right: none;
                color: #fff !important;
                font-weight:bold !important;
                 a {
                    color: #fff;
                  } 
            }
            a {
                color: $black;
                font-weight: 600;
                padding: 5px 1rem;
                @include handheld {
                    padding: 5px .5rem;
                }
            }

        }
    }
    .pageBanner {
        background-size: cover;
        min-height: 280px;
        padding: 25px 25px 0;
        &.skinny {
            min-height: 160px
        }
        &.regular {
            min-height: 160px
        }
        p {
            color: #FFF;
        }
        .button {
            width: fit-content;
            text-transform: inherit;
            font-weight: 300;
        }
    }
    .hero {
        display: block;
        img {
            margin-top: -20%;
            width: 100%;
        }
    }
    .productSliderContainer {
        padding: 40px 0;
    }
}