.piehovercontainer {
    position: relative;
    width: 100%;
    /* min-width:220px; */
    max-width:100%;

    /*@media (max-width: 440px) { 
      max-width:100%;
    }*/
  }

  .remove-hero-margin{
    margin-top: -60px;
  }
  
  .piehoverimage {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 55%;
    left: 45%;
    transform: translate(-45%, -45%);
    -ms-transform: translate(-45%, -45%);
    text-align: left;
    min-width: 100%;
    height:100%;
  }
  
  .piehovercontainer:hover .piehoverimage {
    opacity: 0.15;
  }
  
  .piehovercontainer:hover .middle {
    opacity: 1;
  }
  
  .piehovertext {
    background-color: inherit;
    color: #b5121b;
    font-size: 1em;
    font-weight: 700;
    padding: 0 10px 0 0;
    //font-style: italic;
    @include tablet {
      font-size: .68em;
      line-height: 1.1em;
    }
  }

  .piehover-title{
    font-family: "Bebas Neue", sans-serif;
    font-size: 43px;
    font-weight: 600;
    color: #000;
    text-align: center;
    text-transform: uppercase;
    line-height: 1em !important;
    margin-bottom: 10px;
    @include mobile {
      font-size: 43px;
    }
    @include tablet {
      font-size: 32px;
    }
    @include small-desktop {
      font-size: 36px;
    }
  }

  .pie-title{
    font-family: "Bebas Neue", sans-serif;
    font-size: 43px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    line-height: 1em !important;
    margin-bottom: 10px;
    width:100%;
    position: absolute;
    top: 17%;
    left: 45%;
    transform: translate(-45%, -45%);
    -ms-transform: translate(-45%, -45%);
    @include mobile {
      font-size: 43px;
    }
    @include tablet {
      font-size: 32px;
    }
    @include small-desktop {
      font-size: 36px;
    }
  }


