#recipe {
    h2 {
        color: $black;
        font-family: $secondary;
        text-align: center;
        width: 100%;
        padding: 20px 0;
    }
    h3 {
        font-family: $hero;
        color: $red;
        font-size: 1.8em;
        font-weight: 500;
        font-style: italic;
    }
}