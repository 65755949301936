.video {
    width: 60vw;
    margin: 0 auto;
    
    @include small-desktop {
        width: 75vw;
    }
    @include tablet {
        width: 90%;
    }
    @include handheld {
        width: 100%;
    }
    
    .intrinsic-container {
        @include screen {
            background: $grey3;
            border: 25px solid $grey3;
        }
    }
}