// Resets
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: $body;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
img {
    border: 0;
    max-width: 100%;
}

#pageContent {
    position: relative;
    z-index: 1;
    background-color: white;
}

#mainView {
    background: $white;
    transition: $transition;
    &.navActive {
        // Width of active menu
        transform: translateX(-75vw);
        position: fixed;
        @include screen {
            margin-left: 0;

        }
    }
}

//Grid Overrides
.container.is-fluid {
    max-width: 1600px;
    @include screen {
        padding-left: 60px!important;
        padding-right: 60px!important;
    }
    @include desktop {
        padding-left: 50px!important;
        padding-right: 50px!important;
    }
    @include small-desktop {
        padding-left: 40px!important;
        padding-right: 40px!important;
    }
    @include tablet {
        padding-left: 30px!important;
        padding-right: 30px!important;
    }
    @include mobile {
        padding-left: 25px!important;
        padding-right: 25px!important;
    }
    @include small-mobile {
        padding-left: 20px!important;
        padding-right: 20px!important;
    }
}
.mobilepad { //deprecated
    @include xs-mobile {
        padding-left: 20px!important;
        padding-right: 20px!important;
    }
    @include small-mobile {
        padding-left: 20px!important;
        padding-right: 20px!important;
    }
    @include mobile {
        padding-left: 30px!important;
        padding-right: 30px!important;
    }
    @include tablet {
        padding-left: 30px!important;
        padding-right: 30px!important;
    }
    @include small-desktop {
        padding-left: 30px!important;
        padding-right: 30px!important;
    }
}
.nopad {
    padding: 0!important;
}
.padRight {
    @include screen {
        padding-right: 100px;
    }
    @include small-desktop {
        padding-right: 30px;
    }
}

// Backward Compatibility
header, nav, main, footer, article, section, aside {
   display:block;
}

// Utilities
.pagePadding {
    padding: 80px 0;
    
    @include tablet {
        padding: 70px 0;
    }
    @include mobile {
        padding: 60px 0;
    }
    @include small-mobile {
        padding: 50px 0;
    }
    
}
.clear {
    clear: both;
}
.smooth {
    //transition: color $transition ease-in-out, background $transition ease-in-out, border $transition ease-in-out, transform $transition ease-in-out, opacity $transition ease-in-out, filter $transition ease-in-out, outline $transition ease-in-out;
    transition: all $transition ease-in-out;
}
.overlay {
    &.cover {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    &.darken {
        background: rgba(51, 51, 51, 0.85);
        z-index: 2;
    }
}
.backgroundSettings {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.intrinsic-container {
    position: relative;
    height: 0;
    overflow: hidden;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.intrinsic-container-16x9 {
    padding-bottom: 53.5%;
}
.white {
    background: $white;
}
.lightGrey {
    background: $greyE;
}
.dark {
    background: $grey5;
    
    .card {
        border: none!important;
    }
}
//Background settings
.bg-contain {
    background-size: contain !important;
    background-repeat: no-repeat;
}
.bottomLeft {
    background-position: left bottom;
    background-size: auto !important;
}
.bottomRight {
    background-position: right bottom;
    background-size: auto !important;
}
.bottomCenter {
    background-position: center bottom;
    background-size: auto !important;
}
.centerLeft {
    background-position: left center;
    background-size: auto !important;
}
.centerRight {
    background-position: right center;
    background-size: auto !important;
}
.centerCenter {
    background-position: center center;
    background-size: auto !important;
}
.topLeft {
    background-position: left top;
    background-size: auto !important;
}
.topRight {
    background-position: right top;
    background-size: auto !important;
}
.topCenter {
    background-position: center top;
    background-size: auto !important;
}