.ctaBlock {
    @extend .backgroundSettings;
    padding: 60px 0;
    position: relative;
    
    .content {
        position: relative;
        z-index: 99;
        width: 50%;
        margin-bottom: 0;
        left: 60px;
        
        h4 {
            @extend h3;
            color: $white;
            margin-bottom: 15px;
        }
        p {
            color: $white;
            margin-bottom: 0;
        }
    }
    .action {
        position: relative;
        z-index: 100;
        right: 60px;
    }
    
}