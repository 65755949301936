#alert {
    background: rgb(144,26,25);
    background: linear-gradient(135deg, rgba(144,26,25,1) 13%, rgba(189,52,50,1) 58%, rgba(231,68,24,1) 100%);
    width: 100%;
    padding: 10px;
    text-align: center;
    
    position: relative;
    z-index: 500;
    
    @include small-mobile {
        padding: 10px;
    }
    
    p {
        font-family: $body;
        font-weight: 600;
        color: $white;
        font-size: 16px;
        display: inline-block;
        
        @include mobile {
            font-size: 15px;
        }
        @include small-mobile {
            font-size: 14px;
        }
        @include xs-mobile {
            font-size: 13px;
        }
    }
    i {
        color: $white;
        
        margin-left: 4px;
    }

    &.contains-link {
        &:hover {
            i {
                transform: translateX(3px);
            }
        }
    }
}