.fancyList {
    margin-top: 40px;
    li {
        margin-bottom: 30px;
        img {
            display: inline-block;
            width: 20px;
            position: relative;
            //top: 5px;
            top: -3px;
        }
        h3 {
            display: inline-block;
            font-family: $body;
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 8px;
        }
    }
}