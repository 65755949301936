@import 'bootstrap/bootstrap.scss'; 

/* BASE */
@import 'base/_variables.scss'; 
@import 'base/_mixins.scss';
@import 'base/_base.scss';
@import 'base/_typography.scss';
@import 'base/_buttons.scss';
@import 'base/_inputs.scss';
@import 'base/_error.scss';
@import 'base/hamburgers/hamburgers.scss'; 


/* VENDOR */
/* @import '~bootstrao/dist/aos.css'; */

/* BLOCKS */
@import 'blocks/_mobileNav.scss';
@import 'blocks/_nav.scss';
@import 'blocks/_alert.scss';
@import 'blocks/_hero.scss';
@import 'blocks/_footer.scss';


/* ELEMENTS */
@import 'elements/_postBuilder.scss';
@import 'elements/_pressBuilder.scss';
@import 'elements/_cards.scss';
@import 'elements/_carousel.scss';
@import 'elements/_fancyList.scss';
@import 'elements/_video.scss';
@import 'elements/_accordian.scss';
@import 'elements/_social.scss';
@import 'elements/_cta.scss';
@import 'elements/_breadcrumb.scss';
@import 'elements/_slick.scss';



/* PAGES */
@import 'pages/_home.scss';
@import 'pages/_contact.scss';
@import 'pages/_search.scss';
@import 'pages/_whereToBuy.scss';
@import 'pages/_salesRepLocator.scss';
@import 'pages/_products.scss';
@import 'pages/_bestGetBetter.scss';
@import 'pages/_recipes.scss';
@import 'pages/_letsTalk.scss';
